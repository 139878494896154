import React from "react"

import { Img } from "@components/Img"

import { StimulusView } from "@views/stimulus"

export default () => {
  return (
    <StimulusView
      stimulus={{
        type: "Poem and Image",
        originalSrc: "https://www.artstation.com/artwork/D562d9",
        src: "/stimulus/ozymandias-by-david-dolores/",
        author: "David & Dolores Creative Studio",
        title: "Ozymandias",
      }}
    >
      <section>
        <Img
          style={{
            maxWidth: 600,
          }}
          className="d-block mb-4 mx-auto"
          src="/stimulus/david-amp-dolores-creative-studio-ozymandias-final.jpg"
        />
        <p className="text-center">
          "My name is Ozymandias, King of Kings; Look on my Works, ye Mighty,
          and despair!"
        </p>
      </section>
    </StimulusView>
  )
}
